@media (max-width: @mobile-max) {
  body {
    min-width: 360px;
  }
  .main {
    padding-left: 16px;
    padding-right: 16px;
  }
  .header {
    &__hold {
      padding: 0;
    }
    &__headline {
      h1 {
        font-size: 20px;
      }
    }
  }
  .site {
    &_logo {
      margin: 6px 0;
      &__desktop {
        display: none;
      }
      &__mobile {
        display: inline-block;
      }
    }
    &_hours {
      display: none;
      &_mobile {
        #pageslide & {
          display: inline-block;
          margin: 0 0 5px 35px;
          position: relative;
          color: @elements-color;
          font: bold 19px/1 @primary-font;
          text-decoration: none !important;
          [class^=icon-] {
            width: 23px;
            height: 20px;
            font-size: 18px;
            position: absolute;
          }
          img {
            width: 23px;
            height: 20px;
            font-size: 18px;
            position: absolute;
          }
        }
      }
    }
    &_search {
      width: auto;
      max-width: none;
      opacity: 0;
      visibility: hidden;
      background: @bunting;
      transition: all @primary-transition;
      padding: 10px 15px;
      margin: 0;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      &.open {
        opacity: 1;
        visibility: visible;
      }
      &__hold {
        width: 100%;
        height: 35px;
        background: @elements-color;
        border-radius: 17px;
        display: inline-flex;
      }
    }
    &_search_toggle,
    &_menu_toggle {
      display: inline-block;
    }
    &_side {
      width: 100%;
      &--tightest,
      &--tight {
        width: 100%;
      }
      &--right {
        order: 0;
      }
      &--fill {
        margin-bottom: 0;
      }
    }
    &_date {
      font-size: 13px;
      padding: 0 0 8px 0;
    }
    &_headline {
      justify-content: flex-end;
      flex-flow: row wrap;
      h2 {
        width: 100%;
        padding: 0 0 15px 0;
      }
    }
  }
  .nav {
    &_hold {
      height: 100%;
      flex-flow: column nowrap;
      &__close {
        #pageslide & {
          float: right;
          display: inline-block;
        }
      }
    }
    &_main {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      flex: 1 1 0;
      display: none;
      #pageslide & {
        display: block;
      }
      &__items {
        border-top: 1px solid @deco-color;
      }
      &__links {
        color: @elements-color;
        font: 500 18px/1.2 @primary-font;
        text-decoration: none;
        transition: background @primary-transition;
        padding: 10px 15px;
        align-items: center;
        display: flex;
        .icon-arrow-down {
          font-size: 20px;
          transition: transform @primary-transition;
          margin: 0 0 0 2px;
        }
        .icon-user {
          font-size: 20px;
          margin: 0 8px 0 0;
        }
        &.active {
          .icon-arrow-down {
            transform: rotate(180deg);
          }
        }
      }
      &__drop {
        display: none;
        h3 {
          display: none;
        }
      }
      &__sub {
        padding: 0 0 8px 0;
        &__items {
          display: block;
        }
        &__links {
          color: @elements-color;
          font: bold 15px/1.2 @primary-font;
          text-decoration: none;
          transition: color @primary-transition;
          padding: 5px 15px;
          display: block;
          &.active {
            color: @dove-gray;
          }
        }
        &__sub {
          &__items {
            display: block;
          }
          &__links {
            color: @elements-color;
            font: 14px/1.2 @primary-font;
            text-decoration: none;
            padding: 5px 15px;
            display: block;
          }
        }
      }
    }
    &_side {
      padding: 10px 0;
      margin: 5px 0 30px 0;
      &__links {
        padding: 10px 10px 10px 22px;
      }
      &__sub {
        padding: 20px 20px 20px 22px;
        &__links {
          padding: 10px 10px 10px 20px;
        }
      }
    }
  }
  .body {
    background: none;
    padding-bottom: 16px;
    &__hold {
      &--border {
        padding-bottom: 16px;
      }
    }
  }
  .breadcrumb {
    display: none;
  }
  .content {
    width: 100%;
    &--widest,
    &--wide {
      width: 100%;
    }
    &--right {
      order: 0;
    }
    &__sep {
      padding: 10px 0 0 0;
      margin: 0 0 30px 0;
    }
  }
  .news {
    &_promo {
      width: 100%;
      padding: 0 0 5px 0;
    }
    &_columns,
    &_rows {
      margin: 0 -10px;
      display: flex;
      flex-flow: row wrap;
      &__items {
        width: 50%;
        padding: 0 8px 15px 8px;
        display: block;
        h2 {
          font: bold 15px/1.26 @secondary-font;
        }
      }
    }
    &_list {
      &__items {
        &:first-child {
          padding-top: 0;
        }
      }
      &__body {
        flex-flow: row wrap;
      }
      &__image {
        margin: 0 0 17px 0;
        flex: 0 0 100%;
      }
    }
  }
  .events {
    &_hold {
      width: 100%;
      border-bottom: none;
      padding: 22px 0 16px 0;
    }
    &_list {
      margin: 0 -8px;
      &__items {
        width: 50%;
        padding: 0 8px;
        h2 {
          font: bold 15px/1.26 @secondary-font;
        }
      }
    }
    &_calendar {
      padding: 16px 16px 0 16px;
      margin: 16px 0 0 0;
      &__items {
        &:first-child {
          padding-top: 0;
        }
        h3 {
          font: bold 15px/1.26 @secondary-font;
        }
      }
      &__info {
        margin: 0 15px 0 0;
      }
    }
  }
  .info {
    &_columns {
      margin: 0 -8px 10px -8px;
      &__items {
        padding: 0 8px 10px 8px;
      }
      &--col-3 &__items,
      &--col-4 &__items,
      &--col-5 &__items {
        width: 50%;
      }
    }
  }
  .news_promo,
  .news_columns,
  .news_rows,
  .events_list {
    &__image {
      margin: 0 0 17px 0;
    }
  }
  .exhibitions {
    &_slider {
      width: 100%;
      &__text {
        width: 100%;
        transform: translateY(0);
        padding: 20px 32px 0 32px;
        position: relative;
        top: auto;
        right: auto;
        h2 {
          line-height: 1.3;
        }
      }
      .btn {
        margin: 16px 0;
      }
    }
  }
  .video {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .side {
    &_block {
      .events_calendar + & {
        margin-top: 16px;
      }
      &__items {
        padding: 16px 0 0 0;
      }
      &__links {
        min-height: auto;
        font-size: 24px;
        padding: 30px 10px 30px 20px;
        [class^=icon-] {
          font-size: 60px;
          margin: 0 15px 0 0;
        }
        &--center {
          text-align: left;
          justify-content: flex-start;
          flex-flow: row nowrap;
          [class^=icon-] {
            margin: 0 15px 0 0;
          }
        }
        &--yellow {
          background-size: auto 100px;
          padding-left: 100px;
        }
        &--facebook {
          font-size: 20px;
          padding: 30px 10px 30px 20px;
          [class^=icon-] {
            font-size: 50px;
            margin: 0 15px 0 0;
          }
        }
      }
    }
    &_deco {
      background: none;
      display: none;
    }
  }
  .form {
    &_helpers {
      padding: 0 0 30px 0;
      .selectric-wrapper, .datepicker-js {
        width: 100% !important;
        margin: 0 0 10px 0;
        &:last-child {
          margin: 0;
        }
      }
    }
    &_hold {
      padding: 15px;
      margin: 0;
    }
  }
  .pages {
    &__links {
      display: none;
      &.active,
      &--first,
      &--prev,
      &--next,
      &--last {
        display: inline-flex;
      }
    }
  }
  .img {
    &_center {
      margin: 0 0 17px 0;
    }
  }
  .footer {
    &__top {
      &:before {
        display: none;
      }
    }
    &__middle {
      padding: 12px 0 24px 0;
    }
    &__info {
      width: 100%;
      padding: 20px 0 28px 0;
      margin: 16px 0 0 0;
      &:after {
        left: -16px;
      }
      &__hold {
        column-count: 1;
        column-gap: 0;
        padding: 0;
        margin: 0 0 20px 0;
        p {
          padding: 0;
        }
      }
    }
    &__banners {
      width: 100%;
      padding: 0 0 30px 0;
      &__items {
        padding: 7px;
        display: inline-block;
      }
      &__links {
        display: inline-block;
      }
    }
    &__column {
      width: 100%;
      &:nth-of-type(odd),
      &:nth-of-type(even) {
        border-left: none;
        padding: 0;
      }
      &:first-of-type {
        h4 {
          &:first-of-type {
            border: none;
          }
        }
      }
      &:nth-of-type(odd) &__mobile,
      &:nth-of-type(even) &__mobile {
        display: none;
      }
      h4 {
        font: bold 13px/1.4 @primary-font;
        cursor: pointer;
        border-top: 1px solid #d6d6d6;
        padding: 10px 0;
        align-items: center;
        display: flex;
        &.active {
          .icon-arrow-down {
            transform: rotate(180deg);
          }
        }
        .icon-arrow-down {
          font-size: 20px;
          transition: transform @primary-transition;
          margin: 0 0 0 4px;
          display: inline-block;
        }
      }
      h5 {
        color: @secondary-text-color;
        font: bold 13px/1.4 @primary-font;
        padding: 0 0 8px 0;
      }
    }
    &__copy {
      width: 100%;
      padding: 25px 0;
    }
    &__newsletter {
      width: 100%;
      border-top: 2px solid #615d82;
      border-left: none;
      padding: 20px 0 25px 0;
    }
  }
  .btn {
    padding: 9px 15px;
    &--mobile-block {
      width: 100%;
      display: flex;
    }
    &--round {
      padding: 7px 15px;
    }
    &--desktop {
      display: none;
    }
    &--mobile {
      display: inline-flex;
    }
  }
  #pageslide .mobile_links_wrap {
    :nth-child(2) {
      margin-left: 15px !important;
    }
  }
}
@media (max-width: 823px) {
  .site {
    &_hours {
      display: none;
    }
  }
}
