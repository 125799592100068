html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	font-size: 100%;
	border: 0;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
}
html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
img {
  vertical-align: middle;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
table {
  border-collapse: separate;
	border-spacing: 0;
}
th {
  text-align: left;
}
button, input[type="reset"], input[type="submit"] {
  cursor: pointer;
}
textarea,
input:not([type="checkbox"]):not([type="radio"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
}