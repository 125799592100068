// imports top
@import (less) "config.less";
@import (inline) "normalize.css";
@import (inline) "reset.css";
@import (less) "typografy.less";
@import (inline) "icomoon.css";
@import (inline) "selectric.css";
@import (inline) "slick.css";
@import (less) "plugins.less";
body,
html {
  height: 100%;
}
body {
  color: @text-color;
  font: 15px/1.3 @primary-font;
}
.wrapper {
  height: 1px;
  min-height: 100%;
  flex-flow: column nowrap;
  display: flex;
}
.main {
  width: 100%;
  max-width: 1370px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
.header {
  flex: none;
  &__body {
    background: @deco-color;
    .main {
      position: relative;
      align-items: center;
      justify-content: space-between;
      display: flex;
    }
  }
  &__hold {
    padding: 31px 0 0 0;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-flow: row wrap;
    flex: 1;
  }
  &__clear {
    width: 100%;
    clear: both;
  }
  &__headline {
    background: @mercury;
    padding: 15px 0 0 0;
    margin: 0 0 2px 0;
    .type-1 & {
      background: @tangerine;
    }
    .type-2 & {
      background: @silver-tree;
    }
    h1 {
      color: @text-color;
      font-weight: bold;
      padding: 0 0 17px 0;
      .type-1 &,
      .type-2 & {
        color: @elements-color;
      }
    }
  }
}
.site {
  &_logo {
    margin: 28px 0;
    &__mobile {
      display: none;
    }
  }
  &_hours {
    color: @elements-color;
    font: bold 19px/1 @primary-font;
    text-decoration: none !important;
    border-bottom: 1px solid @elements-color;
    margin: 0 0 0 35px;
    position: relative;
    align-items: center;
    display: inline-flex;
    [class^=icon-] {
      width: 23px;
      height: 20px;
      font-size: 18px;
      position: absolute;
      left: -10px;
    }
    img {
      width: 23px;
      height: 20px;
      font-size: 18px;
      position: absolute;
      left: -10px;
    }
    &_mobile {
      display: none;
    }
  }
  &_search {
    width: 100%;
    max-width: 400px;
    margin: 0 0 0 23px;
    &__hold {
      width: 100%;
      height: 35px;
      background: @elements-color;
      border-radius: 17px;
      display: inline-flex;
    }
    &__input {
      color: @dove-gray;
      font: 500 13px @primary-font;
      background: none;
      border: none;
      padding: 0 14px;
      flex: 1;
      border-right: 1px solid @mercury;
    }
    &__btn {
      color: @text-color;
      font-size: 20px;
      background: none;
      transition: color @primary-transition;
      padding: 0 14px;
      align-items: center;
      display: inline-flex;
      &:hover {
        color: @deco-color;
      }
      [class^=icon-] {
        margin: 0 0 1px 0;
      }
    }
  }
  &_search_toggle,
  &_menu_toggle {
    color: @elements-color;
    font-size: 0;
    text-decoration: none;
    margin: 0 0 0 23px;
    position: relative;
    display: none;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
    }
    .icon-search {
      font-size: 19px;
    }
    .icon-hamburger {
      font-size: 14px;
    }
  }
  &_side {
    width: 38.797%;
    display: flex;
    flex-flow: column nowrap;
    &--tightest {
      width: 250px;
    }
    &--tight {
      width: 28.507%;
    }
    &--right {
      order: 1;
    }
    &--fill {
      background: @bunting;
      margin-bottom: -40px;
    }
  }
  &_date {
    color: @dove-gray;
    font: 500 15px/1.2 @primary-font;
    padding: 0 0 10px 0;
    display: block;
  }
  &_headline {
    padding: 0 0 15px 0;
    align-items: center;
    justify-content: space-between;
    display: flex;
    h2 {
      padding: 0;
    }
    .selectric-wrapper {
      width: 170px;
    }
  }
}
#pageslide {
  width: ~"calc(100% - 54px)";
  max-width: 300px;
  height: 100%;
  background: @bunting;
  display: none;
  position: fixed;
  top: 0;
  z-index: 2;
  &:before {
    width: 100vw;
    height: 100%;
    content: "";
    background: rgba(0,0,0,.3);
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}
}
.nav {
  &_lang {
    margin: 0 0 0 6px;
    &__items {
      margin: 0 0 0 17px;
    }
    &__links {
      color: @elements-color;
      font: bold 19px/1.2 @primary-font;
      text-decoration: none;
    }
  }
  &_hold {
    &__close {
      color: @elements-color;
      font-size: 0;
      text-decoration: none;
      padding: 5px;
      display: none;
      .icon-close {
        font-size: 30px;
      }
    }
  }
  @media (min-width: (@tablet-max + 1)) {
    &_main {
      &__items {
        height: 70px;
        margin: 2px 0 0 30px;
        float: left;
        align-items: center;
        display: inline-flex;
      }
      &__links {
        color: @elements-color;
        font: 500 19px/1.2 @primary-font;
        text-decoration: none;
        transition: background @primary-transition;
        padding: 24px 0;
        position: relative;
        align-items: center;
        display: inline-flex;
        &:after {
          width: 100%;
          height: 5px;
          content: "";
          transition: background @primary-transition;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        &--user {
          background: @bunting;
          border-radius: 17px;
          padding: 6px 8px;
          &:hover {
            background: lighten(@bunting, 10%);
          }
          &:after {
            display: none;
          }
        }
        .icon-arrow-down {
          font-size: 20px;
          margin: 0 0 0 2px;
        }
        .icon-user {
          font-size: 20px;
          margin: 0 8px 0 0;
        }
      }
      &__items:hover &__links {
        &:after {
          background: @fountain-blue;
        }
      }
      &__items:hover &__drop,
	  &__items:focus-within &__drop {
        opacity: 1;
        visibility: visible;
      }
      &__drop {
        opacity: 0;
        visibility: hidden;
        background: @elements-color;
        transition: all @primary-transition;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 25px 25px 40px 25px;
        position: absolute;
        top: 100%;
        left: 20px;
        right: 20px;
        z-index: 2;
        h3 {
          font: bold 24px/1.2 @secondary-font;
          border-bottom: 8px solid @fountain-blue;
          padding: 7px 0 25px 0;
        }
      }
      &__sub {
        column-fill: balance;
        column-count: 3;
        column-gap: 50px;
        padding: 6px 0 0 0;
        &__items {
          break-inside: avoid-column;
          page-break-inside: avoid;
          padding: 22px 0 0 0;
          display: block;
        }
        &__links {
          color: @text-color;
          font: bold 19px/1.2 @primary-font;
          text-decoration: none;
          transition: color @primary-transition;
          display: inline-block;
          &:hover {
            text-decoration: underline;
          }
          &.active {
            color: @dove-gray;
          }
        }
        &__sub {
          padding: 7px 0 0 0;
          &__items {
            padding: 9px 0 0 0;
            display: block;
          }
          &__links {
            color: @dove-gray;
            font: 500 15px/1.2 @primary-font;
            text-decoration: none;
            display: inline-block;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &_side {
    background: @bunting;
    padding: 30px 0;
    &__items {
      margin: 0 0 1px 0;
      display: block;
    }
    &__links {
      color: @elements-color;
      font: bold 15px/1.2 @primary-font;
      text-decoration: none;
      transition: background @primary-transition;
      padding: 10px 20px 10px 32px;
      position: relative;
      align-items: center;
      display: flex;
      &:before {
        width: 4px;
        content: "";
        background: transparent;
        transition: background @primary-transition;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
      &.active {
        background: @deco-color;
        &:before {
          background: @fountain-blue;
        }
        [class^=icon-] {
          transform: rotate(180deg);
        }
      }
      &:hover {
        background: @deco-color;
      }
      [class^=icon-] {
        font-size: 20px;
        transition: transform @primary-transition;
        margin: -2px 0 -1px 4px;
      }
    }
    &__sub {
      background: fade(@deco-color, 40%);
      padding: 20px 20px 20px 32px;
      display: none;
      &.open {
        display: block;
      }
      &__items {
        display: block;
      }
      &__links {
        color: @elements-color;
        font: 500 15px/1.2 @primary-font;
        text-decoration: none;
        border-left: 1px solid @fountain-blue;
        padding: 10px 10px 10px 30px;
        position: relative;
        display: block;
        &:before {
          width: 3px;
          content: "";
          background: transparent;
          transition: background @primary-transition;
          position: absolute;
          top: 0;
          left: -2px;
          bottom: 0;
        }
        &.active,
        &:hover {
          &:before {
            background: @fountain-blue;
          }
        }
      }
    }
  }
  &_share {
    float: right;
    &__items {
      margin: 0 0 0 13px;
      float: left;
    }
    &__links {
      color: @elements-color;
      font-size: 0;
      text-decoration: none;
      transition: color @primary-transition;
      position: relative;
      float: left;
      &:hover {
        color: darken(@elements-color, 10%);
      }
      [class^=icon-] {
        font-size: 27px;
      }
    }
  }
  &_links {
    break-inside: avoid-column;
    page-break-inside: avoid;
    padding: 0 0 22px 0;
    &__items {
      padding: 0 0 5px 0;
    }
    &__links {
      color: @gray;
      font: 500 13px/1.64 @primary-font;
      text-decoration: none;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &_bottom {
    font-size: 0;
    padding: 5px 0 0 0;
    &__items {
      font: 500 13px/1.2 @primary-font;
      display: inline;
      &:first-child {
        &:before {
          display: none;
        }
      }
      &:before {
        content: "|";
        margin: 0 8px;
        display: inline;
      }
    }
    &__links {
      color: @elements-color;
      text-decoration: underline;
      display: inline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.body {
  padding-top: 22px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  &__hold {
    margin: 0 0 15px 0;
    justify-content: space-between;
    display: flex;
    flex-flow: row wrap;
    & + & {
      padding-top: 20px;
    }
    &:last-of-type {
      margin: 0;
    }
    &--border {
      border-bottom: 8px solid @bunting;
      padding-bottom: 40px;
    }
  }
}
.breadcrumb {
  font-size: 0;
  background: @mercury;
  padding: 22px 0 20px 0;
  margin: -22px 0 22px 0;
  &__items {
    font: 500 15px/1.2 @primary-font;
    display: inline;
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: ">";
      margin: 0 10px;
      display: inline;
      position: relative;
      top: -1px;
    }
  }
  &__links {
    color: @secondary-text-color;
    text-decoration: none;
    transition: color @primary-transition;
    display: inline;
    &:hover {
      color: @deco-color;
    }
  }
}
.content {
  width: ~"calc(61.204% - 20px)";
  &--widest {
    width: ~"calc(100% - 270px)";
  }
  &--wide {
    width: ~"calc(71.494% - 20px)";
  }
  &--full {
    width: 100%;
  }
  &--right {
    order: 1;
  }
  &__sep {
    border-bottom: 8px solid @bunting;
    padding: 10px 0 0 0;
    margin: 0 0 30px 0;
    clear: both;
    .type-1 & {
      border-bottom-color: @tangerine;
    }
    .type-2 & {
      border-bottom-color: @silver-tree;
    }
  }
  img {
    max-width: 100%;
  }
  h2 {
    padding-bottom: 10px;
  }
}
.news {
  &_promo {
    width: ~"calc(61.204% - 20px)";
    padding: 0 0 25px 0;
  }
  &_columns {
    margin: 0 -10px;
    display: flex;
    flex-flow: row wrap;
    &__items {
      width: 50%;
      padding: 0 10px 15px 10px;
      h2 {
        font: bold 18px/1.46 @secondary-font;
      }
      p {
        padding: 0 0 10px 0;
      }
    }
  }
  &_rows {
    &__items {
      padding: 0 0 20px 0;
      display: flex;
      h2 {
        font: bold 18px/1.46 @secondary-font;
      }
    }
    &__image {
      margin: 0 20px 0 0;
      flex: 0 0 248px;
    }
  }
  &_list {
    padding: 0 0 20px 0;
    &__items {
      border-top: 1px solid darken(@mercury, 10%);
      padding: 28px 0;
      &:first-child {
        border-top: none;
        padding-top: 15px;
      }
    }
    &__body {
      margin: -6px 0 0 0;
      align-items: flex-start;
      display: flex;
    }
    &__image {
      margin: 6px 20px 27px 0;
      flex: 0 0 248px;
    }
    &__more {
      color: @text-color;
      font: bold 15px/1.2 @primary-font;
      text-decoration: underline;
      align-items: center;
      display: inline-flex;
      [class^=icon-] {
        margin: 1px 0 0 0;
      }
    }
    .btn {
      margin-top: 20px;
    }
  }
}
.events {
  &_hold {
    width: ~"calc(61.204% - 20px)";
    border-top: 8px solid @bunting;
    border-bottom: 8px solid @bunting;
    padding: 30px 0 40px 0;
  }
  &_list {
    margin: 0 -10px;
    display: flex;
    flex-flow: row wrap;
    &__items {
      width: 50%;
      padding: 0 10px 2px 10px;
      h2 {
        font: bold 18px/1.46 @secondary-font;
      }
    }
  }
  &_calendar {
    color: @elements-color;
    background: @deco-color;
    padding: 40px 30px 15px 30px;
    margin: 20px 0 0 0;
    h2 {
      color: @elements-color;
    }
    &__items {
      border-top: 1px solid #7587bd;
      padding: 25px 0;
      align-items: flex-start;
      display: flex;
      &:first-child {
        border-top: none;
        padding-top: 15px;
      }
      h3 {
        font: bold 18px/1.46 @secondary-font;
        a {
          color: @elements-color;
          text-decoration: none;
          transition: color @primary-transition;
          &:hover {
            color: darken(@elements-color, 10%);
          }
        }
      }
    }
    &__info {
      width: 90px;
      color: @deco-color;
      background: @elements-color;
      border-top: 8px solid @elements-color;
      padding: 5px 0 13px 0;
      margin: 0 20px 0 0;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-flow: column nowrap;
      flex: 0 0 auto;
      .type-1 & {
        border-top-color: @tangerine;
      }
      .type-2 & {
        border-top-color: @silver-tree;
      }
      &__month {
        font: 14px/1.2 @primary-font;
        text-transform: capitalize;
      }
      &__day {
        font: bold 42px/1.1 @secondary-font;
        padding: 0 0 3px 0;
      }
      &__time {
        font: bold 14px/1.2 @primary-font;
      }
    }
  }
}
.info {
  &_columns {
    margin: 0 -10px 10px -10px;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    &__items {
      padding: 0 10px 10px 10px;
      [class*=slider] & {
        padding-bottom: 0;
      }
      h2 {
        font: bold 18px/1.46 @secondary-font;
      }
    }
    &--col-3 &__items {
      width: 33.333%;
    }
    &--col-4 &__items {
      width: 25%;
    }
    &--col-5 &__items {
      width: 20%;
    }
  }
  &_columns_slider {
    margin: 0 0 30px 0;
    overflow: hidden;
    .slick-list {
      margin: 0 -10px;
    }
    .slick-arrow {
      width: 42px;
      background: fade(@mercury, 60%);
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      [class^=icon-] {
        font-size: 20px;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
}
.news_promo,
.news_columns,
.events_list,
.info_columns {
  &__image {
    margin: 0 0 20px 0;
  }
}
.news_promo,
.news_columns,
.news_rows,
.news_list,
.events_list,
.info_columns {
  &__image {
    position: relative;
    overflow: hidden;
    display: block;
    &--video {
      &:after {
        height: 54px;
        content: url(../images/play.svg);
        position: absolute;
        top: ~"calc(50% - 27px)";
        left: ~"calc(50% - 27px)";
      }
    }
    img {
      width: 100%;
      transition: transform @primary-transition;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  a {
    text-decoration: none;
    transition: all @primary-transition;
    &:hover {
      color: @deco-color;
    }
  }
}
.exhibitions {
  &_slider {
    width: ~"calc(61.204% - 20px)";
    &__hold {
      position: relative;
    }
    &__panels {
      img {
        width: 100%;
        max-height: 450px;
      }
    }
    &__link {
      color: @elements-color;
      text-decoration: none;
    }
    &__text {
      width: 385px;
      color: @elements-color;
      background: @bunting;
      transform: translateY(-50%);
      padding: 45px 32px 32px 32px;
      position: absolute;
      top: 50%;
      right: 0;
      opacity: 0.9;
      &__label {
        color: @elements-color;
        font: bold 18px/1.2 @secondary-font;
        padding: 0 0 5px 0;
        display: block;
      }
      .site_date {
        color: @elements-color;
      }
      h2 {
        line-height: 1.64;
        font-weight: bold;
        padding-top: 6px;
		    color: @elements-color;
        a {
          color: @elements-color;
          text-decoration: none;
          transition: color @primary-transition;
          &:hover {
            color: darken(@elements-color, 10%);
          }
        }
      }
    }
    .slick-arrow {
      width: 34px;
      height: 64px;
      color: @elements-color;
      font-size: 0;
      text-align: center;
      background: rgba(99, 99, 98, 0.35);
      position: absolute;
      top: ~"calc(50% - 32px)";
      z-index: 1;
      [class^=icon-] {
        font-size: 20px;
        line-height: 64px;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .btn {
      margin: 25px 0 0 0;
    }
  }
}
.podcast {
  &__img {
    position:absolute;
	width: 100%;
	height:100%;
	-o-object-fit: cover;
	object-fit: cover;
  }
}
.video {
  width: ~"calc(61.204% - 20px)";
  .content & {
    width: 100%;
    margin: 0 0 30px 0;
  }
  &__holder {
    height: 0;
    padding-bottom: 56.65%;
    position: relative;
  }
  img{
    height: 450px;
    width: 100%;
	object-fit: cover;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.podcast .video__holder {
	padding-bottom: 66.66%;
}
.accordion {
  padding: 0 0 20px 0;
  &__links {
    color: @text-color;
    font: bold 15px/1.2 @primary-font;
    text-transform: uppercase;
    text-decoration: none;
    background: @mercury;
    padding: 7px 20px 7px 12px;
    margin: 0 0 5px 0;
    position: relative;
    align-items: center;
    display: flex;
    .type-1 & {
      color: @elements-color;
      background: @tangerine;
    }
    .type-2 & {
      color: @elements-color;
      background: @silver-tree;
    }
    &.active {
      [class^=icon-] {
        transform: rotate(180deg);
      }
    }
    [class^=icon-] {
      font-size: 20px;
      transition: transform @primary-transition;
      position: absolute;
      top: ~"calc(50% - 10px)";
      right: 0px;
    }
  }
  &__panels {
    padding: 15px 0 0 0;
    display: none;
  }
}
.side {
  &_block {
    .events_calendar + & {
      margin-top: 20px;
    }
    .footer & {
      height: 100%;
    }
    &__items {
      padding: 20px 0 0 0;
    }
    &__links {
      min-height: 214px;
      color: @elements-color;
      font: bold 32px/1.2 @secondary-font;
      text-decoration: none;
      transition: background @primary-transition;
      padding: 55px;
      position: relative;
      align-items: center;
      display: flex;
      [class^=icon-] {
        font-size: 77px;
        margin: 0 30px 0 0;
      }
      &--center {
        text-align: center;
        justify-content: center;
        flex-flow: column nowrap;
        [class^=icon-] {
          margin: 0 0 25px 0;
        }
      }
      &--yellow {
        background: url(../images/side-block-img-1.svg) no-repeat @tangerine left bottom;
        padding-left: 162px;
        &:hover {
          background-color: darken(@tangerine, 10%);
        }
      }
      &--light-blue {
        background: @deco-color;
        &:hover {
          background: darken(@deco-color, 10%);
        }
      }
      &--middle-blue {
        background: @fountain-blue;
        &:hover {
          background: darken(@fountain-blue, 10%);
        }
      }
      &--green {
        background: @silver-tree;
        &:hover {
          background: darken(@silver-tree, 10%);
        }
      }
      &--dark-blue {
        background: @bunting;
        &:hover {
          background: lighten(@bunting, 10%);
        }
      }
      &--facebook {
        min-height: 0;
        font-size: 24px;
        padding: 25px 25px 25px 40px;
        [class^=icon-] {
          font-size: 56px;
          margin: 0 40px 0 0;
        }
      }
	  &--gray {
        background: @dove-gray;
        &:hover {
          background: lighten(@dove-gray, 10%);
        }
      }
      img {
        max-width: 150px;
        max-height: 150px;
        margin-right: 30px;
		&.full{
			margin:0;
			max-width:initial;
		}
      }
    }
  }
  &_deco {
    height: 100%;
    min-height: 578px;
    background: url(../images/side-deco.png) no-repeat center top 120px;
    margin: 0 0 -20px 0;
  }
}
.calendar {
  padding-top: 15px;
}
.form {
  &_helpers {
    padding: 0 0 30px 0;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    .selectric-wrapper, .datepicker-js {
      width: 170px !important;
      margin-right: 10px;
      &:last-child {
        margin: 0 0 0 auto;
      }
    }
    .datepicker-js {
      background: #fff;
      border: 1px solid #3d3d3d !important;
      border-radius: 20px !important
    }
  }
  &_hold {
    background: @wild-sand;
    padding: 30px 20px;
    margin: 0 0 25px 0;
    .type-1 & {
      border-top: 8px solid @tangerine;
    }
    .type-2 & {
      border-top: 8px solid @silver-tree;
    }
  }
  &_elements {
    max-width: 400px;
    &__items {
      font-size: 0;
      padding: 0 0 20px 0;
      &:last-child {
        padding: 0;
      }
      .btn {
        margin-top: 10px;
      }
    }
    &__label {
      font: bold 13px/1.2 @primary-font;
      padding: 0 0 6px 0;
      display: block;
    }
    &__w-100 {
      max-width: 100px;
    }
    &__w-200 {
      max-width: 200px;
    }
    &__captcha {
      max-width: 100%;
    }
    .ui-datepicker-trigger {
      height: 42px;
      color: @elements-color;
      text-decoration: none;
      vertical-align: top;
      background: @deco-color;
      transform: translateX(-100%);
      transition: background @primary-transition;
      padding: 0 10px;
      align-items: center;
      display: inline-flex;
      &:hover {
        background: darken(@deco-color, 10%);
      }
      [class^=icon-] {
        font-size: 24px;
      }
    }
  }
}
.pages {
  border-top: 8px solid @bunting;
  padding: 20px 0 30px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  &__links {
    width: 36px;
    height: 36px;
    color: @text-color;
    font: 500 19px/1.2 @primary-font;
    text-decoration: none;
    transition: color @primary-transition;
    border-radius: 50%;
    margin: 0 2px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    &.active {
      color: @elements-color;
      background: @deco-color;
      .type-1 & {
        background: @tangerine;
      }
      .type-2 & {
        background: @silver-tree;
      }
    }
    &--prev {
      margin-right: 10px;
    }
    &--next {
      margin-left: 10px;
    }
    &--first,
    &--prev,
    &--next,
    &--last {
      &:hover {
        color: @deco-color;
      }
    }
    [class^=icon-] {
      font-size: 20px;
    }
  }
}
.img {
  &_center {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
.footer {
  overflow: hidden;
  flex: none;
  .main {
    display: flex;
    flex-flow: row wrap;
  }
  &__top {
    color: @elements-color;
    position: relative;
    &:before {
      width: 50vw;
      content: "";
      background: @bunting;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  &__middle {
    background: @mercury;
    padding: 26px 0 34px 0;
  }
  &__bottom {
    color: @elements-color;
    background: @bunting;
  }
  &__info {
    width: 61.204%;
    background: @deco-color;
    padding: 40px 20px 28px 20px;
    position: relative;
    &:after {
      width: 100vw;
      content: "";
      background: @deco-color;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
    &__hold {
      column-count: 2;
      column-gap: 80px;
      padding: 20px 90px 0 90px;
      margin: 0 0 -22px 0;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    a {
      color: @elements-color;
    }
  }
  &__banners {
    width: 100%;
    text-align: center;
    padding: 0 0 54px 0;
    &__items {
      float: left;
      padding: 7px 7px 7px 0;
      display: inline-block;
    }
    &__links {
      display: inline-block;
    }
  }
  @media (min-width: @tablet-min) {
    &__column {
      width: 50%;
      column-fill: balance;
      &:nth-of-type(odd) {
        column-count: 1;
        column-gap: 0;
        padding-right: 50px;
      }
      &:nth-of-type(even) {
        column-count: 2;
        column-gap: 50px;
        border-left: 1px solid #d6d6d6;
        padding-left: 50px;
      }
      &__hold {
        break-inside: avoid-column;
        page-break-inside: avoid;
      }
      &__mobile {
        display: block !important;
      }
      &:nth-of-type(odd) &__mobile {
        column-count: 2;
        column-gap: 50px;
      }
      h4 {
        font: bold 13px/1.4 @primary-font;
        padding: 0 0 18px 0;
        .icon-arrow-down {
          display: none;
        }
      }
      h5 {
        color: @secondary-text-color;
        font: bold 13px/1.4 @primary-font;
        padding: 0 0 8px 0;
      }
    }
  }
  &__copy {
    width: 61.204%;
    padding: 40px 30px 30px 0;
    a {
      color: @elements-color;
    }
  }
  &__newsletter {
    width: 38.797%;
    border-left: 1px solid #615d82;
    padding: 30px 0 30px 30px;
    input {
      margin-bottom: 10px;
    }
    h3 {
      font: bold 24px/1.2 @secondary-font;
      padding: 0 0 7px 0;
    }
    &__label {
      font: 500 13px/2 @primary-font;
      padding: 0 0 12px 0;
      display: block;
    }
    &__hold {
      display: flex;
    }
    &__btn {
      color: @elements-color;
      font: bold 13px/1.2 @primary-font;
      background: @fountain-blue;
      transition: background @primary-transition;
      padding: 10px 32px;
      &:hover {
        background: darken(@fountain-blue, 10%);
      }
    }
  }
  p {
    font: 500 13px/2 @primary-font;
  }
}
.url {
  color: @text-color !important;
  font: 500 15px/1.2 @primary-font;
  text-decoration: none;
  align-items: center;
  display: inline-flex;
  &__wrap {
    text-decoration: underline;
  }
  &:hover &__wrap {
    text-decoration: none;
  }
  .icon-pdf {
    color: #D92539;
    font-size: 25px;
    margin: 0 6px 0 0;
  }
}
.btn {
  text-decoration: none;
  padding: 9px 20px;
  transition: all @primary-transition;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  &--primary {
    color: @elements-color !important;
    font: 500 15px/1.2 @primary-font;
    background: @deco-color;
    &:hover {
      background: darken(@deco-color, 10%);
    }
  }
  &--regular {
    color: @text-color !important;
    font: 15px/1.2 @primary-font;
    background: @mercury;
    &:hover {
      background: darken(@mercury, 10%);
    }
  }
  &--round {
    color: @deco-color;
    font: 500 15px/1.2 @primary-font;
    background: @elements-color;
    border: 1px solid @deco-color;
    border-radius: 20px;
    padding: 7px 15px;
  }
  &--block {
    width: 100%;
  }
  &--mobile {
    display: none;
  }
  & + & {
    margin-left: 5px;
  }
  .icon-calendar,
  .icon-list {
    font-size: 24px;
    margin: 0 5px 0 0;
  }
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]):not(.site_search__input),
textarea {
  width: 100%;
  color: @text-color;
  font: 500 13px @primary-font;
  transition: border @primary-transition;
  border: 1px solid @black;
  border-radius: 0;
  padding: 12px;
}
textarea {
  height: 150px;
  resize: none;
  display: block;
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"])::placeholder,
textarea::placeholder {
  color: @dove-gray !important;
  opacity: 1;
}
input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]):focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}
input.error:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]),
textarea.error {
  border: 1px solid @error !important;
}
.fl {
  float: left !important;
}
.fr {
  float: right !important;
}
.clearfix:after,
.clear {
  height: 0;
  content: "";
  clear: both;
  display: table;
}
.visually-hidden {
  position: absolute;
  top: auto;
  left: -99999px;
}
.login-form {
  input {
    border-color: black !important;
    margin-bottom: 10px;
  }
}
#searchForm {
  #search-searchword {
    border-color: black;
  }
  input {
    margin-bottom: 10px;
  }
}
.details.ni {
  margin-bottom: 20px;
}
#searchSite {
  input {
    margin-bottom: 10px;
    padding-top: 8px;
  }
  .site_search__hold {
    border: 1px solid @black;
    margin-bottom: 10px;
  }
}
.grayscale {
  -webkit-filter: grayscale(100%);
     -moz-filter: grayscale(100%);
       -o-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
          filter: grayscale(100%);
}
iframe, svg {
  max-width: 100% !important;
}
.video_block {
  li:first-child {
    padding: 0;
  }
}

.homepage {
  .video {
    a {
      margin-top: 25px;
    }
  }
}
/*.gallery{
  max-width: 100%;
  margin-bottom: 20px;
  .pictures {
    max-width: 100%;
  }
}*/
.gallery {
  width: 100%;
  a {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    display: inline-block;
    float: left;
    padding: 4px;
    position: relative;
    margin-left: 5px;
    margin-top: 5px;
  }
  img {
  	margin: 0 !important;
  }
  .gallery-in {
  	max-width: 100%;
  }
}

/* CONTACTS */
.categories-list {
  .collapse {
    display: none;
    margin-top: 10px;
  }
  >div {
    border-color: @azure;
    border-style: solid;
    border-width: 1px 1px 1px 6px;
    margin-bottom: 10px;
    padding: 10px;
  }
  >div:hover {
    border-color: lighten(@azure, 10%) !important;
  }
  h1 {
    font-size: 21px;
    font-weight: 600;
    margin: 0 0 7px !important;
    padding: 13px 0 3px 0px !important;
  }
  div h3 {
    font-size: 16px !important;
    font-weight: 600;
  }
  div h3 a {
    text-decoration: none;
  	display: inline;
  }
  div h3 a span {
  	display: inline;
    text-decoration: none;
  }
  div h3 a span:hover {
    text-decoration: none;
  }

  div h3 a:hover {
    text-decoration: underline;
  }

  div h3 a.btn-mini {
    margin-top: 2px;
  	border: medium none;
  	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  	margin-right: -20px;
  	font-size: 11px;
  	color: #1766b0;
  }

  div div div h3 {
    font-size: 14px;
    font-weight: 400;
  }
}
.list-striped {
  list-style: none;
  line-height: 18px;
  text-align: left;
  vertical-align: middle;
  margin-left: 0px;
  li {
    border-top: 1px solid #ddd;
    padding: 5px 0;
  }
  .pull-right {
    padding-bottom: 10px;
  }
  .list-title {
    a {
      color: @black;
      text-decoration: none;
      transition: all @primary-transition;
      font: bold 18px/1.3 @secondary-font;
      &:hover {
        color: @deco-color;
      }
    }
  }
}
.contact-category {
  .collapse {
    display: none;
  }
}

.alphabet {
  .item {
    display: inline;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    list-style: outside none none;
    margin-left: 7px;
  }
  a {
    font: bold 32px/1.3 "PT Serif",serif;
  }
}
.anchored {
  .letter {
    font: bold 32px/1.3 "PT Serif",serif;
    margin-top: 30px;
    text-transform: uppercase;
  }
  p {
    padding: 0;
  }
  a {
    text-decoration: none;
    font-size: 20px;
    transition: color .5s ease;
    &:hover {
      color: @deco-color;
    }
  }
}
.search {
  background-color: #e3e3e3;
  border-color: #dddddd;
  border-style: solid;
  border-width: 1px 1px 3px;
  padding: 15px 15px 7px 15px;
  &_content {
    margin: 0 0 15px 0;
    &__input {
      border-color: @deco-color !important;
      margin: 0 0 15px 0;
    }
  }
  &_list {
    padding: 0 0 20px 0;
    &__items {
      border-top: 1px solid darken(@mercury, 10%);
      padding: 20px 0;
      display: flex;
      &:first-child {
        border-top: none;
      }
    }
    &__image {
      width: 134px;
      flex: 0 0 auto;
      img {
        width: 100%;
      }
    }
    &__text {
      padding: 0 0 0 20px;
      align-items: flex-start;
      justify-content: space-between;
      display: flex;
      flex-flow: column nowrap;
    }
  }
}
.select-search {
  div {
    display: inline-block;
    float: none;
    margin-right: 20px;
    input {
      display: inline-block;
      float: none;
      line-height: 54px;
      margin-bottom: 3px;
      margin-top: 0;
      padding: 0;
    }
  }
}
.middle-box {
  padding: 20px 0;
  text-align: center;
}
.hide {
  display: none;
}
.sitemap {
  li {
    background: url("../../../images/icons/icon-circle.svg") no-repeat;
    background-size: 10px 10px;
    background-position-x: left;
    background-position-y: 7px;
    padding: 0 0 10px 20px;
    a {
      color: @black;
      font: bold 20px/1.3 @secondary-font;
      text-decoration: none;
    }
  }
  ul li {
    background: url("../../../images/icons/icon-dot.svg") no-repeat;
    background-size: 10px 10px;
    background-position-x: left;
    background-position-y: 7px;
    padding: 0 0 10px 20px;
    a {
      color: @black;
      font: bold 20px/1.3 @secondary-font;
      text-decoration: none;
    }
  }
  ul li ul li {
    background: url("../../../images/icons/icon-arrow.svg") no-repeat;
    background-size: 10px 10px;
    background-position-x: left;
    background-position-y: 7px;
    padding: 0 0 10px 20px;
    a {
      color: inherit;
      font: inherit;
    }
  }
  ul.deeper {
    margin-top: 10px;
  }
}
.no-padding {
  padding: 0 !important;
}
#system-message {
  margin-top: 5px;
  margin-bottom: 0px;
  .alert {
    background: #dff0d8 none repeat scroll 0 0;
    border: 1px solid #d6e9c6;
    border-radius: 0px;
    color: #3c763d;
    list-style: outside none none;
    margin: 15px 0;
    padding: 10px 35px 10px 10px;
    .close {
      position: relative;
      top: -2px;
      right: -21px;
      line-height: 20px;
      cursor: pointer;
      color: #3c763d;
      text-decoration: none;
      opacity: 0.5;
      float: right;
      font-size: 20px;
      font-weight: bold;
    }
    h4 {
      color: #3c763d;
    }
  }
}
a.slick-link {
  text-decoration: none;
  transition: all @primary-transition;
  color: @black;
  &:hover {
    color: @deco-color;
  }
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.legend, .only {
  margin-bottom: 10px;
}
.phrases {
  margin-bottom: 10px;
  label {
    display: block;
  }
  input {
    margin-right: 5px;
  }
  .selectric-wrapper {
    margin-top: 10px;
  }
}
.only {
  label {
    display: block;
  }
}
.search_results {
  a {
    text-decoration: none;
    transition: all @primary-transition;
    width: 100%;
    color: @mine-shaft;
    font: 22px/1.3 @secondary-font;
    clear: both;
    &:hover {
      color: @deco-color;
    }
  }
}
.jsn-uniform {
  input[type="text"], input[type="file"] {
    width: 90% !important;
    box-sizing: content-box;
  }
  input[type="radio"] {
    margin-right: 5px;
  }
  .jsn-form-submit {
    text-decoration: none;
    padding: 9px 20px;
    transition: all @primary-transition;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    color: @elements-color !important;
    font: 500 15px/1.2 @primary-font;
    background: @deco-color;
    &:hover {
      background: darken(@deco-color, 10%);
    }
  }
}
.tabs {
  &_content {
    clear: both;
    border: 1px solid #cccccc;
    padding: 10px 10px;
  }
}
.contact {
  h2 {
    margin-top: 10px;
  }
  dt {
    font-weight: 700;
  }
}
.chzn-container {
  display: none !important;
}
@media(min-width: @tablet-min) {
  .special-margin {
    margin-top: -28px !important;
  }
}
// imports bottom
@import (less) "tablet.less";
@import (less) "mobile.less";
