// custom select
.selectric-wrapper {
  .selectric {
    background: @elements-color;
    border: 1px solid @mine-shaft;
    border-radius: 20px;
    .label {
      font: 15px/38px @primary-font;
      margin: 0 30px 0 15px;
    }
    [class^=icon-] {
      font-size: 20px;
      position: absolute;
      top: ~"calc(50% - 10px)";
      right: 10px;
    }
  }
  .selectric-items {
    border-radius: 20px;
    li {
      font-size: 14px;
      padding: 10px 10px 10px 15px;
      &:first-child {
        border-radius: 20px 20px 0 0;
      }
      &:last-child {
        border-radius: 0 0 20px 20px;
      }
    }
  }
  &.selectric-above {
    .selectric-items {
      margin: 0 0 2px 0;
    }
  }
  &.selectric-below {
    .selectric-items {
      margin: 2px 0 0 0;
    }
  }
}

.selectric-site-search {
  >.selectric {
    color: #707070;
    background: 0 0;
    border: none;
    padding: 0 14px;
    flex: 1;
  }
}

@media (max-width: (@tablet-max + 1)) {
  .selectric-site-search {
    width: 40%;
  }
}

// calendar
.ui-datepicker {
	width: 250px;
  background: @elements-color;
	border: 1px solid #D9E1E8;
	padding: 5px;
	display: none;
  .ui-datepicker-header {
    color: @elements-color;
    font: 14px/16px @primary-font;
    text-align: center;
    background: @dove-gray;
    align-items: center;
    justify-content: center;
    display: flex;
    .ui-datepicker-prev, .ui-datepicker-next {
      cursor: pointer;
      border: 3px solid @dove-gray;
      display: inline-block;
    }
    .ui-datepicker-prev {
      padding: 5px;
    }
    .ui-datepicker-next {
      padding: 5px;
      order: 1;
    }
  }
  .ui-datepicker-title {
    margin: 0 auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    th {
      color: @text-color;
      font: 14px/1.2 @primary-font;
      text-align: center;
      border-bottom: 1px solid @mercury;
      padding: 10px 0;
    }
    td {
      color: @text-color;
      font: 14px/1.2 @primary-font;
      text-align: left;
      border-right: 1px solid @mercury;
      border-bottom: 1px solid @mercury;
      &:first-child {
        border-left: 1px solid @mercury;
      }
      &.ui-datepicker-week-end a {
        background: #F3F3F3;
        &:hover {
          color: @elements-color;
          background: @deco-color;
        }
      }
      a {
        color: @text-color;
        font: 14px/1.2 @primary-font;
        text-align: right;
        text-decoration: none;
        padding: 7px 3px 3px 3px;
        display: block;
        &:hover {
          color: @elements-color;
          background: @deco-color;
        }
        &.ui-state-active {
          color: @elements-color;
          background: @deco-color;
          border: 1px solid @deco-color;
          padding: 6px 2px 2px 2px;
          position: relative;
        }
      }
      span {
        color: #000;
        font: 14px/1.2 Quicksand,sans-serif;
        text-align: right;
        text-decoration: none;
        padding: 7px 3px 3px 3px;
        display: block;
      }
    }
  }
}
