h1,
.h1 {
  color: @secondary-text-color;
  font: bold 32px/1.3 @secondary-font;
  padding: 0 0 28px 0;
  clear: both;
  @media (max-width: @tablet-max) {
    font: bold 24px/1.2 @secondary-font;
  }
}
h2,
.h2 {
  width: 100%;
  color: @secondary-text-color;
  font: 24px/1.2 @secondary-font;
  padding: 0 0 22px 0;
  clear: both;
}
ul,
ol {
  &:not([class]) {
    font: 500 15px/1.8 @primary-font;
    text-align: left;
    break-inside: avoid-column;
    page-break-inside: avoid;
    padding: 0 0 27px 0;
    li {
      padding: 0 0 0 18px;
      position: relative;
      &:before {
        position: absolute;
      }
      ul,
      ol {
        &:not([class]) {
          padding-bottom: 0px;
        }
      }
    }
    li:nth-child(n+10) {
      padding: 0 0 0 23px;
    }
  }
}
ul {
  &:not([class]) {
    li {
      &:before {
        width: 4px;
        height: 4px;
        content: "";
        background: @text-color;
        border-radius: 50%;
        top: 12px;
        left: 2px;
      }
    }
  }
}
ol {
  &:not([class]) {
    counter-reset: li;
    flex-flow: column nowrap;
    display: flex;
    li {
      &:before {
        font-weight: bold;
        content: counter(li) ".";
        counter-increment: li;
        top: 0;
        left: 0;
      }
    }
  }
}
dl {
  &:not([class]) {
    padding: 0 0 22px 0;
    flex-flow: row wrap;
    display: flex;
    dt {
      font: 500 13px/1.64 @primary-font;
      padding: 0 0 5px 0;
      flex: 0 0 22%;
      @media (max-width: @mobile-max) {
        padding: 0;
        margin: 0 0 -4px 0;
        flex: 0 0 100%;
      }
    }
    dd {
      font: bold 13px/1.64 @primary-font;
      padding: 0 0 5px 0;
      flex: 0 0 78%;
      @media (max-width: @mobile-max) {
        flex: 0 0 100%;
      }
    }
  }
}
p {
  font: 500 15px/1.8 @primary-font;
  break-inside: avoid-column;
  page-break-inside: avoid;
  padding: 0 0 15px 0;
}
a,
button {
  &:focus-visible {
    outline: 3px solid @black;
    outline-offset: -2px;
  }
}
a {
  &:not([class]) {
    color: @text-color;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.table-responsive {
  overflow-x: auto;
  margin: 0 0 20px 0;
  clear: both;
}
table {
  &:not([class]) {
    width: 100%;
    font: 500 15px/1.3 @primary-font;
    border-bottom: 1px solid @border-color;
    thead {
      th {
        color: @elements-color;
        font: inherit;
        text-align: left;
        background: @text-color;
        border-left: 1px solid @border-color;
        padding: 20px;
      }
    }
    tbody {
      th {
        font-weight: bold;
        border-left: 1px solid @border-color;
        border-top: 1px solid @border-color;
        padding: 16px 20px;
      }
    }
    td {
      border-left: 1px solid @border-color;
      border-top: 1px solid @border-color;
      padding: 16px 20px;
    }
    th,
    td {
      &:first-child {
        border-left: none;
      }
    }
  }
}
@media (max-width: @mobile-max) {
  .table-responsive {
    padding: 0 0 5px 0;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .2);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
