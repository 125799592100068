@media (min-width: @tablet-min) and (max-width: @tablet-max) {
  .main {
    padding-left: 16px;
    padding-right: 16px;
  }
  .header {
    &__hold {
      padding: 0;
    }
  }
  .site {
    &_menu_toggle {
      display: inline-block;
    }
    &_date {
      font-size: 13px;
      padding: 0 0 8px 0;
    }
  }
  .nav {
    &_hold {
      height: 100%;
      flex-flow: column nowrap;
      &__close {
        #pageslide & {
          text-align: right;
          display: block;
        }
      }
    }
    &_main {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      flex: 1 1 0;
      display: none;
      #pageslide & {
        display: block;
      }
      &__items {
        border-top: 1px solid @deco-color;
      }
      &__links {
        color: @elements-color;
        font: 500 18px/1.2 @primary-font;
        text-decoration: none;
        transition: background @primary-transition;
        padding: 10px 15px;
        align-items: center;
        display: flex;
        .icon-arrow-down {
          font-size: 20px;
          transition: transform @primary-transition;
          margin: 0 0 0 2px;
        }
        .icon-user {
          font-size: 20px;
          margin: 0 8px 0 0;
        }
        &.active {
          .icon-arrow-down {
            transform: rotate(180deg);
          }
        }
      }
      &__drop {
        display: none;
        h3 {
          display: none;
        }
      }
      &__sub {
        padding: 0 0 8px 0;
        &__items {
          display: block;
        }
        &__links {
          color: @elements-color;
          font: bold 15px/1.2 @primary-font;
          text-decoration: none;
          transition: color @primary-transition;
          padding: 5px 15px;
          display: block;
          &.active {
            color: @dove-gray;
          }
        }
        &__sub {
          &__items {
            display: block;
          }
          &__links {
            color: @elements-color;
            font: 14px/1.2 @primary-font;
            text-decoration: none;
            padding: 5px 15px;
            display: block;
          }
        }
      }
    }
    &_side {
      padding: 10px 0;
      &__links {
        padding: 10px 10px 10px 22px;
      }
      &__sub {
        padding: 20px 20px 20px 22px;
        &__links {
          padding: 10px 10px 10px 20px;
        }
      }
    }
  }
  .body {
    background: none;
  }
  .news {
    &_promo {
      width: ~"calc(61.204% - 16px)";
    }
    &_columns,
    &_rows {
      margin: 0 -10px;
      display: flex;
      flex-flow: row wrap;
      &__items {
        width: 50%;
        padding: 0 8px;
        display: block;
        h2 {
          font: bold 15px/1.26 @secondary-font;
        }
      }
    }
    &_list {
      &__image {
        margin: 6px 16px 27px 0;
        flex: 0 0 160px;
      }
    }
  }
  .events {
    &_hold {
      width: ~"calc(61.204% - 16px)";
      padding: 30px 0 40px 0;
    }
    &_list {
      margin: 0 -8px;
      &__items {
        width: 50%;
        padding: 0 8px;
        h2 {
          font: bold 15px/1.26 @secondary-font;
        }
      }
    }
    &_calendar {
      padding: 16px 16px 0 16px;
      margin: 16px 0 0 0;
      &__items {
        &:first-child {
          padding-top: 0;
        }
        h3 {
          font: bold 15px/1.26 @secondary-font;
        }
      }
      &__info {
        margin: 0 15px 0 0;
      }
    }
  }
  .info {
    &_columns {
      margin: 0 -8px 10px -8px;
      &__items {
        padding: 0 8px 10px 8px;
      }
      &--col-3 &__items,
      &--col-4 &__items {
        width: 50%;
      }
      &--col-5 &__items {
        width: 33.333%;
      }
    }
  }
  .news_promo,
  .news_columns,
  .news_rows,
  .events_list {
    &__image {
      margin: 0 0 17px 0;
    }
  }
  .exhibitions {
    &_slider {
      width: ~"calc(61.204% - 16px)";
      &__text {
        width: 365px;
        padding: 20px 32px 0 20px;
        h2 {
          line-height: 1.3;
        }
      }
    }
  }
  .video {
    width: ~"calc(61.204% - 16px)";
  }
  .side {
    &_block {
      .events_calendar + & {
        margin-top: 16px;
      }
      &__items {
        padding: 16px 0 0 0;
      }
      &__links {
        min-height: auto;
        font: bold 24px/1.2 @secondary-font;
        text-decoration: none;
        transition: background @primary-transition;
        padding: 30px 10px 30px 20px;
        position: relative;
        align-items: center;
        display: flex;
        [class^=icon-] {
          font-size: 60px;
          margin: 0 15px 0 0;
        }
        &--center {
          [class^=icon-] {
            margin: 0 0 15px 0;
          }
        }
        &--yellow {
          padding-left: 80px;
        }
        &--facebook {
          font-size: 20px;
          padding: 30px 10px 30px 20px;
          [class^=icon-] {
            font-size: 50px;
            margin: 0 15px 0 0;
          }
        }
      }
    }
    &_deco {
      background-size: 100% auto;
    }
  }
  .pages {
    &__links {
      display: none;
      &.active,
      &--first,
      &--prev,
      &--next,
      &--last {
        display: inline-flex;
      }
    }
  }
  .img {
    &_center {
      margin: 0 0 17px 0;
    }
  }
  .footer {
    &__info {
      padding: 20px 20px 28px 20px;
      &__hold {
        column-gap: 40px;
        padding: 0;
      }
    }
    &__column {
      &:nth-of-type(odd) {
        padding-right: 30px;
      }
      &:nth-of-type(even) {
        column-gap: 50px;
        padding-left: 30px;
      }
      &:nth-of-type(odd) &__mobile {
        column-gap: 30px;
      }
    }
  }
}
